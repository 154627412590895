<template>
  <v-sheet class="pa-4" :class="!darkmode && 'grey lighten-5'">
    <v-row no-gutters>
      <v-btn class="font-weight-bold mb-2" depressed @click="$router.go(-1)">
        <v-icon class="mr-2">
          mdi-arrow-left
        </v-icon>
        {{ $t("elearning.content") }}
      </v-btn>
    </v-row>

    <div>
      <v-row no-gutters>
        <v-card
          class="rounded-lg pa-4"
          :class="darkmode ? 'black' : 'white'"
          flat
          width="100%"
        >
          <v-row no-gutters align="start">
            <v-col cols="6" sm="4" class="mt-2">
              <div class="font-weight-light">{{ $t("subjects.subjects") }}</div>
              <div class="font-weight-bold">
                {{ elearning.name }}
                <span v-if="elearning.sub">{{ elearning.sub }}</span>
              </div>
            </v-col>
            <v-col cols="6" sm="4" class="pl-2 mt-2">
              <div class="font-weight-light">{{ $t("app.title") }}</div>
              <div class="font-weight-bold">{{ elearning.title }}</div>
            </v-col>
            <v-col cols="6" sm="4" class="pl-2 mt-2">
              <div class="font-weight-light">{{ $t("app.date") }}</div>
              <div class="font-weight-bold">
                {{ elearning.date | dateFormat }}
              </div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-light">
                {{ $t("basic_competencies.basic_competencies") }}
              </div>
              <div
                class="font-weight-bold"
                v-for="bc in elearning.kd"
                :key="bc.kd_title"
              >
                {{ bc.kd_chapter }}
              </div>
            </v-col>
            <!-- <v-col
              v-if="checkPrivilages('ELR05')"
              cols="12"
              sm="12"
              lg="3"
              :class="$vuetify.breakpoint.lgAndDown && 'mt-4'"
            >
              <v-row
                no-gutters
                :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''"
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  depressed
                  small
                  class="mr-1"
                  @click="modalAddStatus = true"
                >
                  {{ $t("elearning.content") }}
                  <v-icon small class="pl-1">mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  small
                  @click="modalCopyStatus = true"
                >
                  {{ $t("app.copy") }}
                  <v-icon small class="pl-1">mdi-content-copy</v-icon>
                </v-btn>
              </v-row>
            </v-col> -->
          </v-row>
        </v-card>
      </v-row>

      <v-row v-if="loading" justify="center" align="center" class="mt-2">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-row>

      <v-row>
        <v-col cols="12" md="8" lg="9" xl="10">
          <v-card v-if="current && !loading" flat class="mt-4">
            <v-card-text class="pt-6 pb-2">
              <h2 v-html="current.title"></h2>
              <v-divider class="my-4"></v-divider>
              <v-sheet class="mb-4">
                <v-img
                  v-if="current.type == 'IMAGE'"
                  :src="getUrl()"
                  class="rounded-lg"
                  height="auto"
                  width="100%"
                ></v-img>

                <iframe
                  v-if="current.type == 'YOUTUBE'"
                  :src="getUrl()"
                  frameborder="0"
                  height="400px"
                  width="100%"
                  allowfullscreen
                ></iframe>

                <iframe
                  v-if="current.type == 'PDF' && isPDF(current.file) != 'pdf'"
                  :src="
                    `https://view.officeapps.live.com/op/view.aspx?src=${current.file}`
                  "
                  height="400px"
                  width="100%"
                />

                <iframe
                  v-if="current.type == 'PDF' && isPDF(current.file) == 'pdf'"
                  :src="
                    `https://docs.google.com/viewer?url=${current.file}&embedded=true`
                  "
                  height="400px"
                  width="100%"
                />

                <video
                  v-if="current.type == 'VIDEO'"
                  height="auto"
                  width="100%"
                  controls
                >
                  <source :src="getUrl()" />
                </video>

                <v-alert
                  v-if="current.type == 'VIDEO'"
                  class="grey lighten-4 mt-2"
                >
                  <table>
                    <tr>
                      <td style="vertical-align: top">
                        <div>
                          <b>{{ $t("elearning.attention") }}</b>
                        </div>
                      </td>
                      <td class="pl-1">
                        {{ $t("elearning.attention_text_1") }}
                        <v-icon small>mdi-dots-vertical</v-icon>
                        {{ $t("elearning.attention_text_2") }}
                      </td>
                    </tr>
                  </table>
                </v-alert>
              </v-sheet>
              <v-divider v-if="current.type" class="pb-2"></v-divider>
              <v-sheet flat>
                <h4 class="mb-2">{{ $t("app.description") }}</h4>
                <span v-html="current.description"></span>
              </v-sheet>
              <v-divider class="my-2"></v-divider>
              <!-- Elearning Action -->
              <v-row no-gutters class="d-flex justify-space-between mx-1">
                <span>
                  <v-btn
                    :color="contentLike.liked ? 'primary' : 'grey'"
                    fab
                    x-small
                    dark
                    @click="setLikeContent"
                  >
                    <v-icon small>mdi-thumb-up</v-icon>
                  </v-btn>

                  <v-menu
                    v-if="contentLike.count > 0"
                    open-on-hover
                    style="max-width: 600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="ml-2">{{
                        labelContentLike
                      }}</span>
                    </template>
                    <v-list
                      style="max-width: 600px; max-height: 350px; overflow: auto"
                    >
                      <v-list-item
                        v-for="(item, index) in contentLike.detail"
                        :key="index"
                      >
                        <v-list-item-subtitle>{{
                          item.name
                        }}</v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <span v-else class="ml-2">{{ labelContentLike }}</span>
                </span>
                <span>
                  <v-btn color="grey" fab x-small dark @click="scrollToComment">
                    <v-icon>mdi-chat</v-icon>
                  </v-btn>
                  <span class="ml-2"
                    >{{ comment.total }} {{ $t("elearning.comment") }}</span
                  >
                </span>
                <span>
                  <v-menu open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn color="grey" fab x-small dark>
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                        <span class="ml-2">{{ contentView.count }}</span>
                      </span>
                    </template>
                    <v-list
                      style="max-width: 600px; max-height: 350px; overflow: auto"
                    >
                      <v-list-item
                        v-for="(item, index) in contentView.detail"
                        :key="index"
                      >
                        <v-list-item-subtitle>{{
                          item.name
                        }}</v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <!-- Elearning Comment -->

              <v-progress-linear
                v-if="loadingComment"
                indeterminate
                color="primary"
              ></v-progress-linear>
              <v-row no-gutters class="mb-1">
                <a
                  v-if="comment.perpage < comment.total"
                  @click="viewMoreComment"
                  >{{ $t("elearning.view_previous_comment") }}</a
                >
              </v-row>
              <v-row v-for="(com, i) in commentList" :key="com.id" class="mb-3">
                <v-col
                  v-if="!$vuetify.breakpoint.xsOnly"
                  sm="1"
                  align-self="start"
                  class="d-flex align-center"
                >
                  <v-avatar v-if="com.user.photo" size="30">
                    <v-img :src="com.user.photo"></v-img>
                  </v-avatar>
                  <v-avatar v-else color="grey" size="30">
                    <v-icon dark>
                      mdi-account-circle
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="12" sm="11">
                  <div class="d-flex">
                    <h4>{{ com.user.name }}</h4>
                    <v-spacer></v-spacer>
                    <v-icon
                      v-if="
                        com.user.person === $store.getters.g_user.person ||
                          $store.getters.g_role_type === 'ADMIN'
                      "
                      small
                      color="red"
                      @click="deleteComment(com, i)"
                      >mdi-delete</v-icon
                    >
                  </div>
                  <div>{{ com.comment }}</div>
                  <div style="font-size: 10px">{{ com.diff_time }}</div>
                </v-col>
              </v-row>
              <v-row id="commentBox" no-gutters class="mt-4">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  top
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="mr-2">
                      <v-icon>mdi-emoticon</v-icon>
                    </v-btn>
                  </template>
                  <VEmojiPicker class="mr-2" @select="selectEmoji" />
                </v-menu>

                <v-text-field
                  id="commentBox"
                  v-model="commentVal"
                  placeholder="Tulis Komentar"
                  outlined
                  class="rounded-pill"
                  hide-details
                  dense
                  append-icon="mdi-send"
                  autocomplete="off"
                  @click:append="addCommentContent"
                  @keyup.enter="addCommentContent"
                ></v-text-field>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="3" xl="2">
          <v-card flat class="mt-4">
            <v-card-text>
              <div class="font-weight-bold title mb-4">
                {{ $t("elearning.view_others") }}
              </div>
              <div v-for="c in contentList" :key="c.id">
                <v-row
                  @click="$route.params.id != c.id && goToContent(c)"
                  :style="$route.params.id != c.id && 'cursor: pointer'"
                  class="pa-2"
                  :class="
                    $route.params.id == c.id && 'primary white--text rounded'
                  "
                >
                  <v-col cols="4">
                    <div v-if="c.type == 'DOCUMENT'" style="height: 75px">
                      <img :src="asset.document" height="100%" width="100%" />
                    </div>
                    <div v-else-if="c.type == 'YOUTUBE'" style="height: 75px">
                      <img :src="asset.youtube" height="100%" width="100%" />
                    </div>
                    <div v-else-if="c.type == 'VIDEO'" style="height: 75px">
                      <img :src="asset.video" height="100%" width="100%" />
                    </div>
                    <div v-else-if="c.type == 'IMAGE'" style="height: 75px">
                      <img :src="c.file" height="100%" width="100%" />
                    </div>
                    <div v-else style="height: 75px">
                      <img :src="asset.noContent" height="100%" width="100%" />
                    </div>
                  </v-col>
                  <v-col cols="8">
                    <h4>{{ truncateHtml(c.title, "title") }}</h4>
                    <div
                      v-html="
                        $vuetify.breakpoint.smAndDown
                          ? truncateHtml(c.description, 'mobile')
                          : truncateHtml(c.description)
                      "
                    ></div>
                  </v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import i18n from "@/i18n";

import moment from "moment/moment";
import { checkPrivilages } from "@/utils/privilages";

import Youtube from "@/assets/svg/elearning/e-learning_youtube.svg";
import Video from "@/assets/svg/elearning/e-learning_video.svg";
import Document from "@/assets/svg/elearning/e-learning_doc.svg";
import NoContent from "@/assets/svg/elearning/e-learning_nocontent.svg";

import truncate from "truncate-html";

import {
  get_detail_elearning,
  detail_content,
  list_content,
  get_view_content,
  get_like_content,
  set_like_content,
  get_comment_content,
  add_comment_content,
  delete_comment_content
} from "@/api/admin/academic/eLearning";

export default {
  components: {
    VEmojiPicker
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      param: this.$route.params,
      elearning: {
        name: "",
        sub: "",
        title: "",
        kd: "",
        date: ""
      },
      current: {},
      next: null,
      previous: null,
      loading: false,
      length: 0,
      commentVal: "",
      // Content List
      loadingContentList: false,
      queryContent: {
        elearning: this.$route.params.elearning,
        page: 1,
        limit: 100,
        sort: "ASC"
      },
      contentList: [],
      // Content View
      contentView: {
        count: 0,
        detail: []
      },
      // Like view
      contentLike: {
        count: 0,
        detail: [],
        liked: 0
      },
      // Comment
      queryComment: {
        page: 1,
        limit: 2
      },
      commentList: [],
      comment: {
        total: 0,
        perpage: 0
      },
      loadingComment: false,
      asset: {
        document: Document,
        youtube: Youtube,
        video: Video,
        noContent: NoContent
      }
    };
  },
  async created() {
    await this.getDetailElearning();
    this.initialGetData();
  },
  computed: {
    contentId() {
      return this.$route.params.id;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    darkmode() {
      return this.$store.getters.g_darkmode;
    },
    labelContentLike() {
      if (this.contentLike.liked == 1 && this.contentLike.count == 1) {
        return i18n.t("elearning.like.you");
      } else if (this.contentLike.liked == 1 && this.contentLike.count > 1) {
        return `${i18n.t("elearning.like.you_and")} ${this.contentLike.count -
          1} ${i18n.t("elearning.like.others_people")}`;
      } else if (this.contentLike.liked == 0 && this.contentLike.count > 1) {
        return `${this.contentLike.detail[0].name} ${i18n.t(
          "elearning.like.and"
        )} ${this.contentLike.count - 1} ${i18n.t("elearning.like.others")}`;
      } else {
        if (this.contentLike.detail[0]) {
          return `${this.contentLike.detail[0].name}`;
        } else return "";
      }
    }
  },
  methods: {
    deleteComment({ id }, i) {
      delete_comment_content({ id: id }).then(res => {
        if (res.data.code) {
          this.commentList.splice(i, 1);
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: "error"
          });
        }
      });
    },
    truncateHtml: (html, type) => {
      if (type == "title") {
        return truncate(html, 4, { byWords: true, stripTags: true });
      } else {
        if (type == "mobile") {
          return truncate(html, 15, { byWords: true, stripTags: true });
        } else {
          return truncate(html, 6, { byWords: true, stripTags: true });
        }
      }
    },
    async initialGetData(id) {
      this.commentList = [];
      this.getContentList();
      this.getViewContent();
      this.getLikeContent();
      this.getCommentContent(id);
      this.getData(null);
    },
    viewMoreComment() {
      this.queryComment.limit =
        this.queryComment.limit + this.queryComment.limit;
      this.getCommentContent();
    },
    addCommentContent() {
      this.loadingComment = true;
      add_comment_content({
        content: this.contentId,
        comment: this.commentVal
      })
        .then(res => {
          if (res.data.code) this.getCommentContent();
          setTimeout(() => {
            this.commentVal = "";
            this.scrollToComment();
          }, 200);
          this.loadingComment = false;
        })
        .catch(() => (this.loadingComment = false));
    },
    getCommentContent(contentId) {
      this.loadingComment = true;
      get_comment_content({
        ...this.queryComment,
        content: contentId ? contentId : this.contentId
      })
        .then(res => {
          if (res.data.code) {
            this.commentList = [...res.data.data.data].reverse();
            this.comment = {
              total: res.data.data.total,
              perpage: res.data.data.per_page
            };
            this.loadingComment = false;
          }
        })
        .catch(() => (this.loadingComment = false));
    },
    getViewContent() {
      get_view_content({ content: this.contentId }).then(res => {
        if (res.data.code) this.contentView = res.data.data;
      });
    },
    getLikeContent() {
      get_like_content({ content: this.contentId }).then(res => {
        if (res.data.code) this.contentLike = res.data.data;
      });
    },
    setLikeContent() {
      if (this.contentLike.liked == 1) {
        this.contentLike.liked = 0;
        this.contentLike.count = this.contentLike.count - 1;
        this.contentLike.detail.splice(
          this.contentLike.detail.indexOf(this.$store.getters.g_user.fullname),
          1
        );
      } else {
        this.contentLike.liked = 1;
        this.contentLike.count = this.contentLike.count + 1;
        this.contentLike.detail.push({
          name: this.$store.getters.g_user.fullname
        });
      }
      set_like_content({ content: this.contentId });
    },
    getContentList() {
      this.loadingContentList = true;
      list_content(this.queryContent)
        .then(res => {
          if (res.data.code) {
            const data = res.data.data.data;
            const tempData = [];
            data.map(r => {
              let d = r;
              d.type = d.type == "PDF" ? "DOCUMENT" : d.type;
              tempData.push(d);
            });
            this.contentList = data;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loadingContentList = false;
        })
        .catch(() => (this.loadingContentList = false));
    },
    scrollToComment() {
      const el = document.getElementById("commentBox");
      el.scrollIntoView({ behavior: "smooth" });
      el.style.animation = "highlight 3s ease-out";
    },
    selectEmoji(emoji) {
      this.commentVal = this.commentVal + emoji.data;
    },
    goToContent(data) {
      if (data.id) {
        this.$router.replace(
          `/academic/e-learning/${this.$route.params.elearning}/content/${data.id}`
        );
        setTimeout(() => {
          this.initialGetData(data.id);
        }, 200);
      }
    },
    isPDF(path) {
      var extension = path.substr(path.lastIndexOf(".") + 1);
      return extension;
    },
    getUrl() {
      if (this.current && this.current.type == "YOUTUBE") {
        const url = this.current.file;
        if (url) {
          let video_id = url.split("v=")[1];
          if (video_id) {
            const ampersandPosition = video_id.indexOf("&");
            if (ampersandPosition != -1) {
              video_id = video_id.substring(0, ampersandPosition);
            }
            const splitUrl = url.split("watch?v=");
            const newUrl = splitUrl[0] + "embed/" + video_id;
            if (splitUrl[0] != "https://www.youtube.com/" || !splitUrl[1]) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("app.invalid_youtube_url"),
                color: "error"
              });
              return null;
            } else {
              return newUrl;
            }
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("app.invalid_youtube_url"),
              color: "error"
            });
          }
        }
      } else {
        return this.current.file;
      }
    },
    checkPrivilages: name => checkPrivilages(name),
    getDetailElearning() {
      get_detail_elearning({ elearning: this.$route.params.elearning }).then(
        res => {
          if (res.data.code) {
            const d = res.data.data;

            this.elearning = {
              name: d.lesson_name,
              sub: d.sub,
              title: d.title,
              kd: d.basic_competencies,
              date: d.created_at
            };
          }
        }
      );
    },
    getData(item) {
      this.loading = true;
      detail_content({
        elearning: this.$route.params.elearning,
        elearning_content: item ? item.id : this.$route.params.id
      })
        .then(res => {
          if (res.data.code) {
            const data = res.data.data;
            this.current = data.current;
            this.next = data.next;
            this.previous = data.previous;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    }
  }
};
</script>
